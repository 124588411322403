export function isElementInViewport(el: HTMLElement, yPctRequired = 0) {
    let top = el.offsetTop
    let left = el.offsetLeft
    const width = el.offsetWidth
    const height = el.offsetHeight

    while (el.offsetParent) {
        el = el.offsetParent as HTMLElement
        top += el.offsetTop
        left += el.offsetLeft
    }

    const visibleRight = Math.min(window.innerWidth, left + width - window.scrollX)
    const visibleLeft = Math.max(0, left - window.scrollX)
    const xAxisPercentage = ((visibleRight - visibleLeft) / width) * 100

    const visibleBottom = Math.min(window.innerHeight, top + height - window.scrollY)
    const visibleTop = Math.max(0, top - window.scrollY)
    const yAxisPercentage = ((visibleBottom - visibleTop) / height) * 100

    return xAxisPercentage > 0 && yAxisPercentage > yPctRequired
}
