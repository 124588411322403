import React from "react"
import { Container } from "reactstrap"
import ModalRenderer from "../modal/common/ModalRenderer"

interface IProps {
    children?: React.ReactNode
}
const MainLayout = ({ children }: IProps) => {
    return (
        <div className="swiipe-outer-container">
            <Container id="app-container" fluid>
                <div>
                    <div id="main-col">
                        <div id="main">{children}</div>
                    </div>
                </div>
                <ModalRenderer />
            </Container>
        </div>
    )
}
export default MainLayout
