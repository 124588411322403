export function copyToClipboard(element: HTMLInputElement) {
    const isiOSDevice = navigator.userAgent.match(/ipad|iphone/i)

    if (isiOSDevice) {
        const editable = element.contentEditable
        const readOnly = element.readOnly

        element.contentEditable = "true"
        element.readOnly = false

        const range = document.createRange()
        range.selectNodeContents(element)

        const selection = window.getSelection()
        if (!selection) {
            return
        }
        selection.removeAllRanges()
        selection.addRange(range)

        element.setSelectionRange(0, 999999)
        element.contentEditable = editable
        element.readOnly = readOnly
    } else {
        element.select()
    }

    document.execCommand("copy")
}

export function copyToClipboardText(text: string) {
    const inputElement: HTMLInputElement = document.createElement("input")
    inputElement.value = text
    document.body.appendChild(inputElement)

    copyToClipboard(inputElement)

    document.body.removeChild(inputElement)
}
