import React from "react"
import cn from "classnames"
import "./PageWrapper.scss"

interface IPageWrapperProps {
    children: React.ReactNode
    fullWidth?: boolean
    disableMinHeight?: boolean
    hideContent?: boolean
    wider?: boolean
    className?: string
    narrow?: boolean
}

const PageWrapper = ({ fullWidth, children, disableMinHeight, hideContent, wider, narrow, className }: IPageWrapperProps) => {
    return (
        <div
            id="main-container"
            className={cn(
                fullWidth && "full-width",
                disableMinHeight && "disable-min-height",
                hideContent && "d-none",
                wider && "wider",
                narrow && "narrow",
                className
            )}
        >
            {children}
        </div>
    )
}

export default PageWrapper
