import i18next from "i18next"
import { initReactI18next } from "react-i18next"

export const initializeLocales = (language: string | undefined) =>
    i18next
        .use(initReactI18next) // passes i18n down to react-i18next
        .init({
            resources: {
                en: {
                    translation: require("./lang/en.json"),
                },
                da: {
                    translation: require("./lang/da.json"),
                },
            },
            // We hardcode the language to da until we are ready with the english translations
            lng: language ?? navigator.language.split("-")[0],
            fallbackLng: "en",

            interpolation: {
                escapeValue: false,
            },
        })

export const getText = (textId: string) => {
    return i18next.t(textId)
}

export const getLanguage = () => {
    return i18next.language
}
