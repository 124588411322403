import React from "react"
import { IModalBase, IModalControlProps } from "./common/modalTypes"
import BaseModal from "./common/BaseModal"
import ModalFooter from "./common/ModalFooter"
import { ModalBody } from "reactstrap"
import { useTranslation } from "react-i18next"
import "./ModalInfo.scss"

export interface IModalInfo extends IModalBase {
    type: "info"
    text: string
}

const ModalInfo = ({ title, text, callback, ...otherProps }: IModalInfo & IModalControlProps) => {
    const { t } = useTranslation()
    return (
        <BaseModal {...otherProps} modalClassName="modal-info" callback={callback} centered title={title}>
            <ModalBody>
                <div className="content-container">
                    <p className="mb-0 text-center">{text}</p>
                </div>
            </ModalBody>
            <ModalFooter rightButton={{ onClick: () => callback("accepted"), text: t("common.close") }} />
        </BaseModal>
    )
}

export default ModalInfo
