import { ThunkAction } from "redux-thunk"
import { StoreState } from "../StoreState"
import { AnyAction } from "redux"
import { requestThunk } from "./serverThunks"
import { IUrls } from "../../type/serverConfiguration"
import { endpoints } from "../../config/endpoints"
import { configurationReducerActions } from "../reducers/configurationReducer"

export const initializeThunk = (): ThunkAction<void, StoreState, null, AnyAction> => async (dispatch, getState) => {
    const serverConfiguration = await dispatch(requestThunk<IUrls>(endpoints.Self.getConfiguration))

    dispatch(configurationReducerActions.setServerConfiguration(serverConfiguration))

    dispatch(configurationReducerActions.setInitialized(true))
}
