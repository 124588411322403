import { IApiEndpoint, TEndpointName } from "./../type/request/IApiEndpoint"
import { THttpMethod } from "./../type/request/THttpMethod"

export interface IRequestOptions {
    isOpen?: boolean
    endpoint?: TEndpointName
}

function create0(method: THttpMethod, path: string, options?: IRequestOptions): IApiEndpoint {
    return {
        method,
        endpoint: options?.endpoint ?? "InternalApi",
        authentication: options?.isOpen ? "open" : "login",
        path,
    }
}

function create1(method: THttpMethod, path: string, options?: IRequestOptions): (param1: string) => IApiEndpoint {
    return (param1: string) => {
        return create0(method, path.replace(/{[^}]*}/, param1), options)
    }
}

function create2(method: THttpMethod, path: string, options?: IRequestOptions): (param1: string, param2: string) => IApiEndpoint {
    return (param1: string, param2: string) => {
        return create0(method, path.replace(/{[^}]*}/, param1).replace(/{[^}]*}/, param2), options)
    }
}

const apiEndpoints = {
    Self: {
        getConfiguration: create0("get", "/api/configuration/GetServerConfigurationEndpoints", { endpoint: "Self" }),
        createTestRecommendationUrl: create0("post", "/order/createTestUrl", { endpoint: "Self" }),
    },
}

type IApiEndpoints = typeof apiEndpoints
export const endpoints: IApiEndpoints = apiEndpoints
