export function getKeys<T extends string | number>(object?: { [key in T]?: any }): T[] {
    if (!object) {
        return []
    }
    return Object.keys(object) as T[]
}

export function getValues<T>(object?: { [key: string | number]: T }): T[] {
    if (!object) {
        return []
    }
    return Object.values(object) as T[]
}

export function getEntries<T>(object?: { [key: string | number]: T }): { key: string | number; value: T }[] {
    if (!object) {
        return []
    }
    return Object.entries(object).map(([key, value]) => ({ key, value }))
}
